import zlRequest from '../../../index'

import type { IUserList, WxUserCount } from './types'

import { reqDataType } from '../../../types'

enum userAPI {
  user = '/user',
  userList = '/user/list',
  userCount = '/user/wxUserCount'
}

export async function getWxuserList(page: number) {
  return zlRequest.get<reqDataType<IUserList[]>>({
    url: userAPI.userList,
    params: {
      page
    }
  })
}

export async function getWxUserCount() {
  return zlRequest.get<reqDataType<WxUserCount>>({
    url: userAPI.userCount
  })
}

export async function deleteWxUser(id: number) {
  return zlRequest.delete({
    url: userAPI.user + '/' + id
  })
}

export async function updateUserAuth(id: number, auth: number) {
  return zlRequest.patch({
    url: userAPI.user + '/' + id + '/wxuser',
    data: {
      auth
    }
  })
}
