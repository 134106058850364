let BASE_URL = ''
const TIME_OUT = 10000
if (process.env.NODE_ENV === 'development') {
  // BASE_URL = 'https://192.168.1.158:8806'
  BASE_URL = '/api'
} else if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'https://gpct.oneqw.com:8080'
} else {
  // BASE_URL = 'https://192.168.1.158:8806'
  BASE_URL = '/api'
}

export { BASE_URL, TIME_OUT }
