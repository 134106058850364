import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import YDay from './day'
import { DatePicker } from '../components/page-orderGood'
dayjs.extend(utc)

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export function handleDate(arr: any[], filed: string) {
  arr.forEach((item) => {
    item[filed] = formatUtcString(item[filed])
  })
}

export function formatUtcString(
  utcString: string,
  format: string = DATE_TIME_FORMAT
) {
  return dayjs.utc(utcString).utcOffset(8).format(format)
}

export function datePickerFormat(date: string, format = 'YYYY-MM-DD') {
  if (date) return dayjs(date).format(format)
  return ''
}

function _setDatePickerDay(someday: number) {
  const day = YDay.getDay(someday)
  const date = new Date(day)
  return date.setTime(date.getTime())
}

export function getDatePickerTimeStampArray() {
  const timestamp: any[] = []
  let text = ''
  for (let i = 1; i <= 3; i++) {
    const config: DatePicker = {}
    switch (i) {
      case 1:
        text = '明天'
        break

      case 2:
        text = '后天'
        break

      case 3:
        text = '大后天'
        break
    }
    config.text = text
    config.value = () => {
      return _setDatePickerDay(i)
    }
    config.value()

    timestamp.push(config)
  }

  return timestamp
}

// export function formatTimestamp(
//   timestamp: number,
//   format: string = DATE_TIME_FORMAT
// ) {
//   return ''
// }
