import { Module } from 'vuex'
import { ElMessage } from 'element-plus'

import router from '@/router'

import type { ILoginState } from './types'
import type { IRootState } from '../types'
import type { IAccount, ILoginUserInfo } from '@/service/login/types'

import { accountLoginRequest, requestUserInfoById } from '@/service/login/login'
import localCache from '@/utils/cache'

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state() {
    return {
      token: '',
      userInfo: {}
    }
  },
  mutations: {
    changeToken(state, token: string) {
      state.token = token
    },
    changeUserInfo(state, userInfo: ILoginUserInfo) {
      state.userInfo = userInfo
    }
  },
  getters: {},
  actions: {
    async accountLoginAction({ commit }, payload: IAccount) {
      // 1.实现登录逻辑
      const loginResult = await accountLoginRequest(payload)
      const { status, data } = loginResult

      if (status !== 200) {
        const errmsg: any = data
        ElMessage({
          message: errmsg,
          type: 'error'
        })
      }

      const { id, token } = loginResult.data

      commit('changeToken', token)
      localCache.setCache('token', token)

      // 2.获取用户信息
      const userInfo = await requestUserInfoById(id)

      commit('changeUserInfo', userInfo.data)
      localCache.setCache('userInfo', userInfo.data)

      // 3.跳转到首页
      router.push('/main')
    },

    loadLocalLogin({ commit }) {
      const token = localCache.getCache('token')
      if (token) {
        commit('changeToken', token)
      }

      const userInfo = localCache.getCache('userInfo')
      if (userInfo) {
        commit('changeUserInfo', userInfo)
      }
    }
  }
}

export default loginModule
