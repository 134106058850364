import ZLrequest from './request/request'
import localCache from '@/utils/cache'
import router from '@/router'
import { BASE_URL, TIME_OUT } from './request/config'
import type { reqDataType } from './types'

const zlRequest = new ZLrequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      // 携带token的拦截
      const token = localCache.getCache('token')
      if (token) {
        config.headers = config.headers ?? {}
        config.headers.Authorization = `Bearer ${token}`
        // 您可以使用!(non-nullable assertion operator) 告诉 TypeScript 一个属性不是未定义的或为 null 的（假设您 100% 确定），或者检查它并在它未定义时分配它。
        // config.headers!.Authorization = `Bearer ${token}`
      }

      return config
    },
    requestInterceptorCatch: (err) => {
      return err
    },
    responseInterceptor: (res) => {
      return res
    },
    responseInterceptorCatch: (err) => {
      const res = err.response

      if (res.status === 401 && res.data === '无效token') {
        localCache.deleteCache('token')
        router.push('/login')
        res.data = '登录凭证已过期或无效，请重新登录'
      }

      return err.response
    }
  }
})

export function authToken() {
  return zlRequest.post<reqDataType<string>>({
    url: '/jwt'
  })
}

export default zlRequest
