import zlRequest from '@/service'

enum dateGoodListAPI {
  dateGoodList = '/food'
}

import type { reqDataType } from '@/service/types'

import type { DateGoodList } from './types'

export async function getAlldateGood(date: string) {
  return zlRequest.get<reqDataType<DateGoodList>>({
    url: dateGoodListAPI.dateGoodList + `/${date}/foodList`,
    params: {
      date
    }
  })
}

export async function deleteGood(id: number) {
  return zlRequest.delete<reqDataType<string>>({
    url: dateGoodListAPI.dateGoodList + '/' + id + '/dateGood'
  })
}

// export async function get
