import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/theme-chalk/base.css'
import 'element-plus/theme-chalk/el-loading.css'
import 'element-plus/theme-chalk/el-message.css'
import 'element-plus/theme-chalk/el-table.css'
import 'element-plus/theme-chalk/el-scrollbar.css'
import 'element-plus/theme-chalk/el-icon.css'
import 'element-plus/theme-chalk/el-col.css'
import 'element-plus/theme-chalk/el-row.css'
import 'element-plus/theme-chalk/el-input.css'
import 'element-plus/theme-chalk/el-drawer.css'
import 'element-plus/theme-chalk/el-message-box.css'
import 'element-plus/theme-chalk/el-pagination.css'
import 'element-plus/theme-chalk/el-notification.css'

export function globalRegister(app: any) {
  app.use(ElementPlus, {
    locale: zhCn
  })
}
