import { Module } from 'vuex'
import { IRootState } from '../../../types'

import { IUserListState } from './types'
import { IUserList } from '@/service/main/user/wxuser/types'

import { getWxuserList } from '@/service/main/user/wxuser/wxuser'

import { handleDate } from '@/utils/data-format'

const wxuser: Module<IUserListState, IRootState> = {
  namespaced: true,
  state() {
    return {
      userList: []
    }
  },
  mutations: {
    changeUserList(state, list: IUserList[]) {
      state.userList = list
    }
  },
  actions: {
    async getWxUserListAction({ commit }) {
      const list = await getWxuserList(0)
      handleDate(list.data, 'createAt')
      commit('changeUserList', list.data)
    }
  }
}

export default wxuser
