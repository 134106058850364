import zlRequest from '../index'

import type { IAccount, ILoginResult, ILoginUserInfo } from './types'

import type { reqDataType } from '../types'

enum LoginAPI {
  AccountLogin = '/login',
  LoginUserInfo = '/user/',
  UserMenu = '/role/'
}

export function accountLoginRequest(account: IAccount): Promise<ILoginResult> {
  return zlRequest.post<ILoginResult>({
    url: LoginAPI.AccountLogin,
    data: account
  })
}

export function requestUserInfoById(id: number) {
  return zlRequest.get<reqDataType<ILoginUserInfo>>({
    url: LoginAPI.LoginUserInfo + id
  })
}
