import { RouteRecordRaw } from 'vue-router'

import type { IMenuConfig } from '@/components/nav-menu/types/types'

let fristMenu = ''

export const mountRoute = (router: RouteRecordRaw[]) => {
  router.forEach((item) => {
    if (item.path === '/main') {
      const mainChildren: RouteRecordRaw[] = item.children ? item.children : []
      // 1.先去加载默认所有的routes
      const routeFiles = require.context('../router/main', true, /\.ts/)

      routeFiles.keys().forEach((key, index) => {
        const route = require('../router/main' + key.split('.')[1])
        if (index === 0) {
          fristMenu = route.default.path
        }
        mainChildren.push(route.default)
      })
    }
  })
}

export function handleMenuConfig(menus: IMenuConfig[], Pindex = 0) {
  menus.forEach((item, index) => {
    index++
    if (item.chidren) {
      Pindex = index
      item.index = Pindex + ''
      handleMenuConfig(item.chidren, index)
    } else {
      item.index = Pindex + '-' + index
    }
  })
}

// export function handleMenuDefaultActive(
//   path: string,
//   menus: IMenuConfig[]
// ): string {
//   let activeIndex = ''

//   for (const menu of menus) {
//     if (menu.chidren) {
//       menu.chidren.forEach((item) => {
//         if (item.path === path && item.index) {
//           activeIndex = item.index
//         }
//       })
//     }
//   }

//   return activeIndex
// }
interface IActiveItem {
  index: string
  breadcrumb: string[]
}
const activeItem: IActiveItem = {
  index: '',
  breadcrumb: []
}
let pb = ''
export function handleMenuDefaultActive(path: string, menus: IMenuConfig[]) {
  const active = menus.find((item) => {
    if (item.chidren) {
      pb = item.title
      handleMenuDefaultActive(path, item.chidren)
    } else {
      if (path === item.path) {
        activeItem.breadcrumb[0] = pb
        activeItem.breadcrumb[1] = item.title
        pb = ''
      }
      return path === item.path
    }
  })

  if (!activeItem.index) {
    if (active?.index) activeItem.index = active.index
  }
  return activeItem
}

export { fristMenu }
