import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
dayjs.extend(weekday)

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

class YDay {
  getDay(addDay: number) {
    return dayjs().add(addDay, 'day').format('YYYY-MM-DD')
  }

  today(format = 'YYYY-MM-DD') {
    return dayjs().format(format)
  }

  tomorrow(format = 'YYYY-MM-DD', endHour = 0) {
    const day = dayjs() > dayjs().hour(endHour) ? 1 : 0
    return dayjs().add(day, 'day').format(format)
  }

  getWeekTime(day: number) {
    const timeArray = []
    for (let i = 0; i < day; i++) {
      const time = dayjs().add(i, 'day').format('YYYY-M-D')
      const nday = _switchDay(dayjs().add(i, 'day').day())
      const splitTime = time + '  ' + nday
      timeArray.push(splitTime)
    }

    function _switchDay(nowDay: number) {
      let message = ''
      switch (nowDay) {
        case 0:
          message = '(星期天)'
          break

        case 1:
          message = '(星期一)'
          break

        case 2:
          message = '(星期二)'
          break

        case 3:
          message = '(星期三)'
          break

        case 4:
          message = '(星期四)'
          break

        case 5:
          message = '(星期五)'
          break

        case 6:
          message = '(星期六)'
          break
      }

      return message
    }

    return timeArray
  }

  formatUtcString(utcString: string, format: string = DATE_TIME_FORMAT) {
    return dayjs.utc(utcString).utcOffset(8).format(format)
  }
}

export default new YDay()
