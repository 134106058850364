import zlRequest from '../../../index'

enum goodsAPI {
  base = '/food',
  deletePrePic = '/upload/food/img',
  deleteGood = '/food',
  uploadGoodMenu = 'food/date'
}

import type { goodType, IFoodInfo, IGoodDate, IUpdateInfo } from './types'

// Promise<goodType[]> promise 中 res的数据类型
export function getGoodList(): Promise<goodType> {
  return zlRequest.get<goodType>({
    url: goodsAPI.base
  })
}

export function addFood(foodInfo: IFoodInfo): any {
  return zlRequest.post({
    url: goodsAPI.base,
    data: foodInfo
  })
}

export function delPrePic(filename: string): Promise<string> {
  return zlRequest.delete<string>({
    url: goodsAPI.deletePrePic,
    data: {
      filename
    },
    showLoading: false
  })
}

export function deleteGood(id: number) {
  return zlRequest.delete<IGoodDate>({
    url: goodsAPI.deleteGood + '/' + id
  })
}

export function updateGood(id: number, info: IUpdateInfo) {
  return zlRequest.patch<IGoodDate>({
    url: goodsAPI.base + '/' + id,
    data: info
  })
}

export function updateGoodMenu(date: string, type: number, list: any[]) {
  return zlRequest.post<IGoodDate>({
    url: goodsAPI.uploadGoodMenu,
    data: {
      date,
      type,
      list
    }
  })
}
