import { Module } from 'vuex'

import type { IRootState } from '../../types'
import type { IGoodListType } from './types'
import type { IGoodDataType } from '@/service/main/goods/goodlist/types'

import { getGoodList } from '@/service/main/goods/goodlist/goodlist'

import { getAlldateGood } from '@/service/main/goods/dateGoodList/dateGoodList'
import type { dateGood } from '@/service/main/goods/dateGoodList/types'

import localCache from '@/utils/cache'

import { formatUtcString } from '@/utils/data-format'

let dateGoodListArray: string[] = []

const goodListModule: Module<IGoodListType, IRootState> = {
  namespaced: true,
  state() {
    return {
      goodList: [],
      picList: [],
      dateGoodList: []
    }
  },
  mutations: {
    changeGoodList(state, list: IGoodDataType[]) {
      state.goodList = list
    },
    changePicList(state, list: string[]) {
      state.picList = list
    },
    changeDateGoods(state, list: dateGood[]) {
      list.forEach((item) => {
        state.dateGoodList.push(item)
      })
    },
    clearDateGood(state) {
      state.dateGoodList = []
      dateGoodListArray = []
    }
  },
  getters: {},
  actions: {
    async goodListAction({ commit }) {
      const { data: goodList } = await getGoodList()
      const pics: string[] = []

      goodList.forEach((item) => {
        item.createAt = formatUtcString(item.createAt)
        if (item.imgUrl) {
          item.imgUrl = item.imgUrl + '?' + Math.random()
          pics.push(item.imgUrl)
        }
      })
      goodList.reverse()
      pics.reverse()
      commit('changePicList', pics)
      commit('changeGoodList', goodList)
      localCache.setCache('goodList', goodList)

      localCache.setCache('goodList', goodList)
    },
    async clearDateGoodListAction({ commit }) {
      commit('clearDateGood')
    },
    async dateGoodListAction({ commit }, date: string) {
      if (date === '') return
      const findDate = dateGoodListArray.find((item) => {
        return item === date
      })

      if (findDate) return

      const { data: dateGoodList } = await getAlldateGood(date)
      if (!dateGoodList.data) return
      dateGoodListArray.push(date)
      dateGoodList.data.forEach((item) => {
        item.createAt = formatUtcString(item.createAt)
        item.imgUrl = item.imgUrl + '?' + item.id
      })
      commit('changeDateGoods', dateGoodList.data)
    }
  }
}

export default goodListModule
